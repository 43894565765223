








import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import AVersionInfo from "@auditcloud/components/snippets/AVersionInfo.vue";

@Component({
  components: { AVersionInfo },
})
export default class AAppFooter extends Vue {
  @Prop({
    type: String,
  })
  status!: string;
}
